import type { Map } from 'maplibre-gl'

export const addClusterMarkers = (map: Map) => {
  const pictoUrl = '/assets/Frame-8.svg'
  const secondPictoUrl = '/assets/Frame-9.svg'

  const img = new Image(24, 36)
  const secondImg = new Image(24, 36)

  img.src = pictoUrl
  secondImg.src = secondPictoUrl

  img.onload = () => {
    map.addImage(`light-blue-marker`, img, {
      pixelRatio: 36,
    })
    map.addImage(`deep-blue-marker`, secondImg, {
      pixelRatio: 36,
    })
  }

  img.onerror = err => {
    console.log('Image not loaded : ', err)
  }

  map.addLayer({
    id: 'unclustered-point',
    type: 'symbol',
    source: 'earthquakes',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'custom-marker',
      'icon-size': 25,
      'icon-allow-overlap': true,
    },
  })

  map.on('zoom', () => {
    if (process.env.CHANGE_COLOR_ON_ZOOM !== 'false') {
      const layoutProperty = map.getLayoutProperty(
        'unclustered-point',
        'icon-image',
      )
      if (map.getZoom() >= 5.7 && layoutProperty !== 'deep-blue-marker') {
        map.setLayoutProperty(
          'unclustered-point',
          'icon-image',
          'deep-blue-marker',
        )
      } else if (
        map.getZoom() <= 5.7 &&
        layoutProperty !== 'light-blue-marker'
      ) {
        map.setLayoutProperty(
          'unclustered-point',
          'icon-image',
          'light-blue-marker',
        )
      }
    } else {
      map.setLayoutProperty(
        'unclustered-point',
        'icon-image',
        'light-blue-marker',
      )
    }
  })

  map.on('mouseenter', 'unclustered-point', () => {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', 'unclustered-point', () => {
    map.getCanvas().style.cursor = ''
  })
}
