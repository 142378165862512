import type { GeoJSONFeatureCollection } from '@/types/geojson'
import type { Map } from 'maplibre-gl'

export const addMarker = ({
  map,
  centroids,
  language,
}: {
  map: Map
  centroids: GeoJSONFeatureCollection
  language: 'fr' | 'en'
}) => {
  map.addSource('centroids', {
    type: 'geojson',
    data: centroids,
  })

  const pictoUrl = '/assets/Frame-8.svg'
  const secondPictoUrl = '/assets/Frame-9.svg'

  const img = new Image(24, 36)
  const secondImg = new Image(24, 36)

  img.src = pictoUrl
  secondImg.src = secondPictoUrl

  img.onload = () => {
    map.addImage(`centroids-light-blue-marker`, img, {
      pixelRatio: 36,
    })
    map.addImage(`centroids-deep-blue-marker`, secondImg, {
      pixelRatio: 36,
    })
  }

  img.onerror = err => {
    console.log('Image not loaded : ', err)
  }

  map.addLayer({
    id: 'centroids',
    type: 'symbol',
    source: 'centroids',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'centroids-light-blue-marker',
      'icon-size': 25,
      'icon-allow-overlap': true,
    },
  })

  map.on('mouseenter', 'centroids', () => {
    map.getCanvas().style.cursor = 'pointer'
  })

  map.on('mouseleave', 'centroids', () => {
    map.getCanvas().style.cursor = ''
  })

  // if (process.env.CHANGE_COLOR_ON_ZOOM !== 'false') {
  //   map.on('zoom', () => {
  //     const layoutProperty = map.getLayoutProperty('centroids', 'icon-image')
  //     if (
  //       map.getZoom() >= 6 &&
  //       layoutProperty !== 'centroids-deep-blue-marker'
  //     ) {
  //       map.setLayoutProperty(
  //         'centroids',
  //         'icon-image',
  //         'centroids-deep-blue-marker',
  //       )
  //     } else if (map.getZoom() <= 6 && layoutProperty !== 'light-blue-marker') {
  //       map.setLayoutProperty(
  //         'centroids',
  //         'icon-image',
  //         'centroids-light-blue-marker',
  //       )
  //     }
  //   })
  // }

  map.on('click', 'centroids', (e: maplibregl.MapLayerMouseEvent) => {
    if (
      (language === 'fr' && e.features![0].properties.fr_url) ||
      (language === 'en' && e.features![0].properties.en_url)
    ) {
      if (language === 'fr') {
        window.open(e.features![0].properties.fr_url, '_blank')
      } else {
        window.open(e.features![0].properties.en_url, '_blank')
      }
    } else {
      e.preventDefault()
    }
  })
}
