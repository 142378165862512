import { useEffect } from 'react'
import maplibregl from 'maplibre-gl'
import {
  isMapboxURL,
  transformMapboxUrl,
} from 'maplibregl-mapbox-request-transformer'
import type { GeoJSONFeatureCollection } from '@/types/geojson'
import { addLayerColor } from '@/utils/addLayerColor'
import { addMarker } from '@/utils/addMarker'
import { addCluster } from '@/utils/addCluster'
import { addClusterMarkers } from '@/utils/addClusterMarkers'

export default function Map({
  countries,
  frenchAgencies,
  centroids,
  language,
}: {
  countries: GeoJSONFeatureCollection | null
  frenchAgencies: GeoJSONFeatureCollection | null
  centroids: GeoJSONFeatureCollection | null
  language: 'fr' | 'en'
}) {
  useEffect(() => {
    const mapboxKey = process.env.MAPBOX_KEY

    const transformRequest = (url?: string, resourceType?: string) => {
      if (isMapboxURL(url!)) {
        return transformMapboxUrl(url!, resourceType!, mapboxKey!)
      }
    }

    const map: maplibregl.Map = new maplibregl.Map({
      container: 'map',
      center: [-30, 40],
      zoom: 1.7,
      style: process.env.MAPBOX_URL!,
      transformRequest,
      maxZoom: 9,
    })

    map.on('load', () => {
      map.addControl(
        new maplibregl.NavigationControl({ showCompass: false }),
        'bottom-right',
      )
      map.dragRotate.disable()
      map.touchZoomRotate.disableRotation()
      if (centroids && countries) {
        addLayerColor({ map, countries, centroids, language })
        addMarker({ map, centroids, language })
      }
      if (frenchAgencies) {
        addCluster({ map, frenchAgencies, language })
        addClusterMarkers(map)
      }

      map.setLayoutProperty('country-label', 'text-field', [
        'get',
        `name_${language}`,
      ])
    })

    return () => map.remove()
  }, [countries, frenchAgencies, centroids, language])

  return <div id="map" style={{ width: '100%', height: '100vh' }}></div>
}
