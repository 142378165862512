import createPopup from '@/utils/createPopup'
import type { GeoJSONFeatureCollection } from '@/types/geojson'
import { LngLat, type Map } from 'maplibre-gl'

export const addLayerColor = ({
  map,
  countries,
  centroids,
  language,
}: {
  map: Map
  countries: GeoJSONFeatureCollection
  centroids: GeoJSONFeatureCollection
  language: 'fr' | 'en'
}) => {
  map.addSource('maine', {
    type: 'geojson',
    data: countries,
  })

  map.addLayer({
    id: 'maine',
    type: 'fill',
    source: 'maine',
    layout: {},
    paint: {
      'fill-opacity': 0,
    },
  })

  const { popup } = createPopup({ className: 'centroid-popup', anchor: 'left' })

  countries.features.forEach(country => {
    map.addSource(country.properties!.fr_short!, {
      type: 'geojson',
      data: country,
    })

    map.addLayer({
      id: country.properties!.fr_short!,
      type: 'fill',
      source: country.properties!.fr_short!,
      layout: {},
      paint: {
        'fill-color': process.env.PRIMARY_COLOR,
        'fill-opacity': country.properties?.fr_short === 'France' ? 1 : 0,
      },
    })

    map.on(
      'mouseenter',
      country.properties!.fr_short!,
      (e: maplibregl.MapLayerMouseEvent) => {
        const actualCountry = centroids.features.filter(
          feature =>
            feature.properties?.fr_short === e.features![0].properties.fr_short,
        )

        if (actualCountry.length > 0) {
          const coordinates = (
            actualCountry[0].geometry as GeoJSON.Point
          ).coordinates.slice()
          const description =
            actualCountry[0].properties![
              (language + '_short') as 'fr_short' | 'en_short'
            ]

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          const center = new LngLat(coordinates[0], coordinates[1])

          popup
            .setLngLat(center)
            .setHTML(
              `<div><div><img src='/assets/+.svg' /><h2>${description}</h2></div></div>`,
            )
            .addTo(map)

          setTimeout(function () {
            popup.addClassName('transition')
          }, 100)

          popup.getElement().addEventListener('click', () => {
            console.log('im in', country.properties)
            if (
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              (language === 'fr' && country.properties?.fr_url) ||
              (language === 'en' && country.properties?.en_url)
            ) {
              if (language === 'fr') {
                window.open(country.properties.fr_url, '_blank')
              } else {
                window.open(country.properties.en_url, '_blank')
              }
            }
          })
        }
      },
    )
  })

  const mousemoveHandler = (e: maplibregl.MapLayerMouseEvent) => {
    const markerLayer = map.getLayer('centroids')
    const countryLayer = map.getLayer('maine')

    const features = map.queryRenderedFeatures(e.point, {
      layers: [markerLayer!.id],
    })

    const countryFeature = map.queryRenderedFeatures(e.point, {
      layers: [countryLayer!.id],
    })

    if (features.length === 0) {
      if (countryFeature.length === 0) {
        popup.remove()
        map.off('mousemove', mousemoveHandler)
      }
    }
  }

  map.on('mouseout', 'maine', () => {
    map.on('mousemove', mousemoveHandler)
  })

  map.on('zoom', () => {
    const currentZoom = map.getZoom()
    const maxZoom = 30

    const zoomLevel = 1 - currentZoom / maxZoom

    const features = map.querySourceFeatures('France')

    if (zoomLevel < 0.81) {
      features.forEach(() => {
        const opacity = zoomLevel < 0 ? 0 : zoomLevel > 0.87 ? 1 : zoomLevel
        const targetCountry = 'France'

        map.setPaintProperty('France', 'fill-opacity', [
          'case',
          ['==', ['get', 'fr_short'], targetCountry],
          0,
          opacity > 1 ? 1 : opacity * 1.2,
        ])
      })
    } else {
      const opacity = zoomLevel < 0 ? 0 : zoomLevel > 0.87 ? 1 : zoomLevel

      map.setPaintProperty('France', 'fill-opacity', opacity > 1 ? 1 : opacity)
    }

    // code to display country names
    // if (zoomLevel < 0.89) {
    //   centroids.features.forEach(marker => {
    //     const div = document.createElement('div')
    //     div.className = 'countryNameMarker'
    //     const textElement = document.createElement('span')
    //     textElement.textContent = marker.properties!.fr_short!

    //     div.appendChild(textElement)

    //     const newCoordinates = [
    //       marker.geometry.coordinates[0],
    //       (marker.geometry.coordinates[1] as number) +
    //         (marker.geometry.coordinates[1] as number) / 25,
    //     ]

    //     new maplibregl.Marker({ element: div })
    //       .setLngLat(newCoordinates as LngLatLike)
    //       .addTo(map)
    //   })
    // } else {
    //   if (document.querySelector('.countryNameMarker')) {
    //     centroids.features.forEach(() => {
    //       const countryNameMarker = document.querySelector('.countryNameMarker')
    //       countryNameMarker?.remove()
    //     })
    //   }
    // }
  })
}
