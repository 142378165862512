import createPopup from '@/utils/createPopup'
import type { GeoJSONFeatureCollection, IFeature } from '@/types/geojson'
import maplibregl, {
  type GeoJSONSource,
  type LngLatLike,
  Map,
  Marker,
  LngLat,
} from 'maplibre-gl'

export const addCluster = ({
  map,
  frenchAgencies,
  language,
}: {
  map: Map
  frenchAgencies: GeoJSONFeatureCollection
  language: 'fr' | 'en'
}) => {
  const { popup } = createPopup({ className: 'popup', anchor: 'bottom' })

  const pictoUrl = '/assets/rectangle.svg'

  const img = new Image(22, 22)

  img.src = pictoUrl

  img.onload = () => {
    map.addImage('rectangle', img, {
      pixelRatio: 36,
    })
  }

  img.onerror = err => {
    console.log('Image not loaded : ', err)
  }

  const SPIDERIFY_AFTER_ZOOM = 9
  const SPIDER_TYPE = 'layer'
  const MAX_LEAVES_TO_SPIDERIFY = 255
  const CIRCLE_TO_SPIRAL_SWITCHOVER =
    SPIDER_TYPE.toLowerCase() === 'marker' ? 10 : 15

  const CIRCLE_OPTIONS = {
    distanceBetweenPoints: 50,
  }

  const SPIRAL_OPTIONS = {
    rotationsModifier: 1250,
    distanceBetweenPoints: SPIDER_TYPE.toLowerCase() === 'marker' ? 42 : 32,
    radiusModifier: 50000,
    lengthModifier: 1000,
  }

  const SPIDER_LEGS_LAYER_NAME = `spider-legs-${Math.random()
    .toString(36)
    .substr(2, 9)}`
  const SPIDER_LEGS_PAINT_OPTION = {
    'line-width': 3,
    'line-color': 'rgba(128, 128, 128, 0.5)',
  }

  const SPIDER_LEAVES_LAYER_NAME = `spider-leaves-${Math.random()
    .toString(36)
    .substr(2, 9)}`

  let clusterMarkers: any = []
  let spiderifiedCluster = { id: '', coordinates: [0, 0] }

  function clearSpierifiedMarkers() {
    if (clusterMarkers.length > 0) {
      for (let i = 0; i < clusterMarkers.length; i++) {
        clusterMarkers[i].remove()
      }
    }
    clusterMarkers = []
  }

  function removeSourceAndLayer(map: Map, id: string) {
    if (map.getLayer(id) != null) map.removeLayer(id)
    if (map.getSource(id) != null) map.removeSource(id)
  }

  function clearSpiderifiedCluster() {
    removeSourceAndLayer(map, SPIDER_LEGS_LAYER_NAME)
    removeSourceAndLayer(map, SPIDER_LEAVES_LAYER_NAME)
    clearSpierifiedMarkers()
  }

  function generateEquidistantPointsInCircle({
    totalPoints = 1,
    options = CIRCLE_OPTIONS,
  }) {
    const points = []
    const theta = (Math.PI * 2) / totalPoints
    let angle = theta
    for (let i = 0; i < totalPoints; i++) {
      angle = theta * i
      points.push({
        x: options.distanceBetweenPoints * Math.cos(angle),
        y: options.distanceBetweenPoints * Math.sin(angle),
      })
    }
    return points
  }

  function generateEquidistantPointsInSpiral({
    totalPoints = 10,
    options = SPIRAL_OPTIONS,
  }) {
    const points = [{ x: 0, y: 0 }]
    const rotations = totalPoints * options.rotationsModifier
    const distanceBetweenPoints = options.distanceBetweenPoints
    const radius = totalPoints * options.radiusModifier
    const thetaMax = rotations * 2 * Math.PI
    const awayStep = radius / thetaMax
    for (
      let theta = distanceBetweenPoints / awayStep;
      points.length <= totalPoints + options.lengthModifier;

    ) {
      points.push({
        x: Math.cos(theta) * (awayStep * theta),
        y: Math.sin(theta) * (awayStep * theta),
      })
      theta += distanceBetweenPoints / (awayStep * theta)
    }
    return points.slice(0, totalPoints)
  }

  function generateLeavesCoordinates({ nbOfLeaves }: { nbOfLeaves: number }) {
    let points
    if (nbOfLeaves < CIRCLE_TO_SPIRAL_SWITCHOVER) {
      points = generateEquidistantPointsInCircle({
        totalPoints: nbOfLeaves,
      })
    } else {
      points = generateEquidistantPointsInSpiral({
        totalPoints: nbOfLeaves,
      })
    }
    return points
  }

  function spiderifyCluster({
    map,
    source,
    clusterToSpiderify,
  }: {
    map: Map
    source: string
    clusterToSpiderify: { id: string; coordinates: number[] }
  }) {
    const spiderlegsCollection: IFeature[] = []
    const spiderLeavesCollection: IFeature[] = []

    const geoJsonSource = map.getSource(source) as GeoJSONSource

    geoJsonSource.getClusterLeaves(
      parseInt(clusterToSpiderify.id),
      MAX_LEAVES_TO_SPIDERIFY,
      0,
      (error: Error | null | undefined, features: any) => {
        const leavesCoordinates = generateLeavesCoordinates({
          nbOfLeaves: features.length,
        })

        const clusterXY = map.project(
          clusterToSpiderify.coordinates as LngLatLike,
        )
        features.forEach((element: IFeature, index: number) => {
          const spiderLeafLatLng = map.unproject([
            clusterXY.x + leavesCoordinates[index].x,
            clusterXY.y + leavesCoordinates[index].y,
          ])

          if (SPIDER_TYPE.toLowerCase() === 'marker') {
            clusterMarkers.push(
              new maplibregl.Marker().setLngLat(spiderLeafLatLng),
            )
          }
          if (SPIDER_TYPE.toLowerCase() === 'layer') {
            spiderLeavesCollection.push({
              type: 'Feature',
              properties: {
                fr_short: element.properties!.fr_short,
                fr_url: element.properties?.fr_url,
                en_url: element.properties?.en_url,
                address: element.properties!.address,
              },
              geometry: {
                type: 'Point',
                coordinates: [spiderLeafLatLng.lng, spiderLeafLatLng.lat],
              },
            })
          }

          spiderlegsCollection.push({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                clusterToSpiderify.coordinates,
                [spiderLeafLatLng.lng, spiderLeafLatLng.lat],
              ],
            },
          })
        })

        map.addLayer({
          id: SPIDER_LEGS_LAYER_NAME,
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: spiderlegsCollection,
            },
          },
          paint: SPIDER_LEGS_PAINT_OPTION,
        })

        if (SPIDER_TYPE.toLowerCase() === 'marker') {
          clusterMarkers.forEach((marker: Marker) => marker.addTo(map))
        }
        if (SPIDER_TYPE.toLowerCase() === 'layer') {
          map.addLayer({
            id: SPIDER_LEAVES_LAYER_NAME,
            type: 'symbol',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: spiderLeavesCollection,
              },
            },
            layout: {
              'icon-image': 'rectangle',
              'icon-size': 22,
              'icon-allow-overlap': true,
            },
          })

          map.on(
            'mouseenter',
            SPIDER_LEAVES_LAYER_NAME,
            (e: maplibregl.MapLayerMouseEvent) => {
              map.getCanvas().style.cursor = 'pointer'

              const coordinates = (
                e.features![0].geometry as GeoJSON.Point
              ).coordinates.slice()
              const description = e.features![0].properties.fr_short
              const address = e.features![0].properties.address

              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
              }

              const center = new LngLat(coordinates[0], coordinates[1])

              popup
                .setLngLat(center)
                .setHTML(
                  `<div><h2>${description}</h2><span></span>${address}</div>`,
                )
                .addTo(map)
            },
          )

          map.on('mouseleave', SPIDER_LEAVES_LAYER_NAME, () => {
            map.getCanvas().style.cursor = ''
            popup.remove()
          })
        }
      },
    )
  }

  map.addSource('earthquakes', {
    type: 'geojson',
    data: frenchAgencies,
    cluster: true,
    clusterMaxZoom: 10,
    clusterRadius: 50,
  })
  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'earthquakes',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        process.env.SECONDARY_COLOR!,
        100,
        process.env.SECONDARY_COLOR!,
      ],
      'circle-radius': ['step', ['get', 'point_count'], 15, 100, 30, 750, 40],
    },
  })

  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'earthquakes',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      'text-justify': 'center',
    },
    paint: {
      'text-color': process.env.PRIMARY_COLOR,
    },
  })

  if (process.env.CHANGE_COLOR_ON_ZOOM !== 'false') {
    map.on('zoom', () => {
      const paintProperty: any = map.getPaintProperty(
        'clusters',
        'circle-color',
      )

      const colorOfCluster: string = paintProperty[2]

      if (
        map.getZoom() >= 5.7 &&
        colorOfCluster !== process.env.PRIMARY_COLOR
      ) {
        map.setPaintProperty('clusters', 'circle-color', [
          'step',
          ['get', 'point_count'],
          process.env.PRIMARY_COLOR,
          100,
          process.env.SECONDARY_COLOR,
        ])
        map.setPaintProperty('cluster-count', 'text-color', '#ffffff')
      } else if (
        map.getZoom() <= 5.7 &&
        colorOfCluster !== process.env.SECONDARY_COLOR
      ) {
        map.setPaintProperty('clusters', 'circle-color', [
          'step',
          ['get', 'point_count'],
          process.env.SECONDARY_COLOR,
          100,
          process.env.SECONDARY_COLOR,
        ])
        map.setPaintProperty(
          'cluster-count',
          'text-color',
          process.env.PRIMARY_COLOR,
        )
      }
    })
  }

  map
    .on('click', 'clusters', e => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      })
      const clusterId = features[0].properties.cluster_id
      if (map.getZoom() < SPIDERIFY_AFTER_ZOOM) {
        const geoJsonSource = map.getSource('earthquakes') as GeoJSONSource

        geoJsonSource.getClusterExpansionZoom(
          clusterId,
          (err: Error | null | undefined, zoom: number | null | undefined) => {
            map.easeTo({
              center: (features[0].geometry as GeoJSON.Point)
                .coordinates as LngLatLike,
              zoom: zoom!,
            })
          },
        )
      } else {
        spiderifiedCluster = {
          id: clusterId,
          coordinates: (features[0].geometry as GeoJSON.Point).coordinates,
        }
        spiderifyCluster({
          map: map,
          source: 'earthquakes',
          clusterToSpiderify: spiderifiedCluster,
        })
      }
    })
    .on('click', SPIDER_LEAVES_LAYER_NAME, () => {
      if (map.getLayer(SPIDER_LEAVES_LAYER_NAME) != null) {
        if (language === 'fr') {
          window.open('https://colasrail.com/fr/france/', '_blank')
        } else {
          window.open('https://colasrail.com/en/france/', '_blank')
        }
      }
      clearSpiderifiedCluster()
    })
    .on('zoomstart', () => {
      clearSpiderifiedCluster()
    })

  map.on('click', 'unclustered-point', (e: maplibregl.MapLayerMouseEvent) => {
    const coordinates = (e.features![0].geometry as GeoJSON.Point).coordinates
    const center = new LngLat(coordinates[0], coordinates[1])
    map.flyTo({
      center: center,
    })
    if (
      (language === 'fr' && e.features![0].properties.fr_url) ||
      (language === 'en' && e.features![0].properties.en_url)
    ) {
      if (language === 'fr') {
        window.open(e.features![0].properties.fr_url, '_blank')
      } else {
        window.open(e.features![0].properties.en_url, '_blank')
      }
    }
  })

  map.on(
    'mouseenter',
    'unclustered-point',
    (e: maplibregl.MapLayerMouseEvent) => {
      map.getCanvas().style.cursor = 'pointer'

      const coordinates = (
        e.features![0].geometry as GeoJSON.Point
      ).coordinates.slice()
      const description = e.features![0].properties.fr_short
      const address = e.features![0].properties.address

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      const center = new LngLat(coordinates[0], coordinates[1])

      popup
        .setLngLat(center)
        .setHTML(`<div><h2>${description}</h2><span></span>${address}</div>`)
        .addTo(map)
    },
  )

  map.on('mouseleave', 'unclustered-point', () => {
    map.getCanvas().style.cursor = ''
    popup.remove()
  })

  map.on('mouseenter', 'clusters', () => {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', 'clusters', () => {
    map.getCanvas().style.cursor = ''
  })

  map.on('click', () => {
    if (map.getLayer(SPIDER_LEAVES_LAYER_NAME) != null) {
      clearSpiderifiedCluster()
    }
  })
}
