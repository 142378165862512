import maplibregl, { type Offset } from 'maplibre-gl'

const createPopup = ({
  className,
  anchor,
}: {
  className: string
  anchor: 'left' | 'bottom'
}) => {
  const markerHeight = 20,
    markerRadius = 10,
    linearOffset = 25
  const popupOffsets: Offset =
    anchor === 'bottom'
      ? {
          'top': [0, 0],
          'top-left': [0, 0],
          'top-right': [0, 0],
          'bottom': [0, -markerHeight],
          'bottom-left': [
            linearOffset,
            (markerHeight - markerRadius + linearOffset) * -1,
          ],
          'bottom-right': [
            -linearOffset,
            (markerHeight - markerRadius + linearOffset) * -1,
          ],
          'left': [markerRadius, (markerHeight - markerRadius) * -1],
          'right': [-markerRadius, (markerHeight - markerRadius) * -1],
          'center': [0, 0],
        }
      : {
          'top': [0, 0],
          'top-left': [0, 0],
          'top-right': [0, 0],
          'bottom': [0, 0],
          'bottom-left': [0, 0],
          'bottom-right': [0, 0],
          'left': [-19, -5.2],
          'right': [0, 0],
          'center': [0, 0],
        }

  const popup = new maplibregl.Popup({
    closeButton: false,
    closeOnClick: false,
    className: className,
    offset: popupOffsets,
    anchor: anchor,
  })

  return { popup }
}

export default createPopup
