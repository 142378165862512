import { useEffect, useState } from 'react'
import Map from './components/Map'
import type { GeoJSONFeatureCollection } from './types/geojson'
import Plausible from 'plausible-tracker'

export default function App() {
  const [countries, setCountries] = useState<null | GeoJSONFeatureCollection>(
    null,
  )
  const [frenchAgencies, setFrenchAgencies] =
    useState<null | GeoJSONFeatureCollection>(null)
  const [centroids, setCendroids] = useState<null | GeoJSONFeatureCollection>(
    null,
  )
  const [language, setLanguage] = useState<'fr' | 'en'>('fr')

  useEffect(() => {
    if (process.env.PLAUSIBLE_TRACKING) {
      const plausible = Plausible({
        domain: process.env.PLAUSIBLE_TRACKING,
        apiHost: 'https://analytics.lc.tools',
        hashMode: true,
      })
      plausible.trackPageview()
    }

    const searchParams = new URLSearchParams(window.location.search)
    const langParam = searchParams.get('lang')

    if (langParam && langParam === 'en') {
      setLanguage('en')
    }

    const fetchCountries = async () => {
      const reponse = await fetch('/data/countries.geojson')
      const countries = await reponse.json()
      setCountries(countries)
    }
    const fetchFrenchAgencies = async () => {
      const reponse = await fetch('/data/frenchAgencies.geojson')
      const agencies = await reponse.json()
      setFrenchAgencies(agencies)
    }
    const fetchCentroids = async () => {
      const reponse = await fetch('/data/centroides_4326.geojson')
      const centroids = await reponse.json()
      setCendroids(centroids)
    }
    fetchCountries().catch(err => console.log(err))
    fetchFrenchAgencies().catch(err => console.log(err))
    fetchCentroids().catch(err => console.log(err))
  }, [])

  return (
    <div>
      <Map
        countries={countries}
        frenchAgencies={frenchAgencies}
        centroids={centroids}
        language={language}
      />
      {debug && <p>in debug</p>}
    </div>
  )
}
